/// <reference path="../../../atoms/DrivingSafetyResultListBase/js/drivingsafetyresultlistbase.webc.js" />

/**
 * DrivingSafetyLocationsForTrainingList List of all Locations for one Training.
 *
 * @author  <riccardo.jung@merkle.com>
 * @class DrivingSafetyLocationsForTrainingList
*/

// eslint-disable-next-line no-undef
class DrivingSafetyLocationsForTrainingList extends DrivingSafetyResultListBase {

	_readConfiguration() {
		super._readConfiguration();
		this.config = this.$ctx.data('config');
		this.api = this.config.api;
		this.trainingId = this.config.trainigid;
		this.isB2BComponent = this.config.b2b === ("True" || "true") ? true : false;
		this.advertisigKey = this.config.advertisingKey;
	}

	_requestData() {
		T.Utils.View.startLoader();
		this._clearWrapper();

		const payload = {
			trainingID: this.trainingId
		};

		if (this.isB2BComponent) {
			payload.b2b = true;
		}

		const request = {
			method: 'POST',
			data: payload,
			url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this.api),
			headers: { "Ocp-Apim-Subscription-Key": T.Utils.Application.getApiMSubscriptionKey() },
			contentType: "application/x-www-form-urlencoded;charset=utf-8"
		};
		T.Utils.Ajax.fragment(request, this._handleEventDataSuccess.bind(this), this._handleEventDataFailed.bind(this));
	}

	_clearWrapper() {
		this._$resultWrapper.empty();
	}

	_createResultItems() {
		for (let i = 0; i < this._data.length; i++) {
			const itemData = this._data[i];
			if (itemData) {
				const item = this._createLocationResultItem(itemData);
				this._appendResultItemToView(item, itemData);
			}
		}
	}

	handleAppointmentSearchClick(itemData, e) {
		e.preventDefault();

		if (itemData.training !== undefined) {
			this.setTrainingModelToStore({
				Name: itemData.training.name,
				Id: itemData.training.id,
				Link: itemData.training.detailLink,
				Desc: itemData.training.headline
			});
		}

		super.handleAppointmentSearchClick(itemData, e);
	}

	_appendResultItemToView($resultItem, itemData) {
		if (itemData) {
			this._$resultWrapper.append($resultItem);
			this._$resultWrapper.toggleClass("h-hidden", false);
			T.Utils.Helper.initAdditionalModules(this.$ctx);
		}
	}

	_displayButtons($resultItem) {
		$resultItem.find(".js-btn-appointment").toggleClass("h-hidden", false);
		$resultItem.find(".js-btn-location").toggleClass("h-hidden", true);
		if (!this.isB2BComponent) {
			$resultItem.find(".js-btn-coupon").toggleClass("h-hidden", false);
		}
	}
}
window.customElements.define('driving-safety-locations-for-training-list', DrivingSafetyLocationsForTrainingList);