/// <reference path="../../../atoms/DrivingSafetyResultListBase/js/drivingsafetyresultlistbase.webc.js" />

/**
 * DrivingSafetyLocationList List of all Locations for one Training.
 *
 * @author  <marc.radziwill@merkle.com>
 * @class DrivingSafetyLocationList
*/

// eslint-disable-next-line no-undef
class DrivingSafetyLocationList extends DrivingSafetyResultListBase {

	btnAppointment = '.js-btn-appointment';
	couponSelector = '.js-btn-coupon';

	_connectedCallback() {
		this.hideLocationLink = true;
		super._connectedCallback();
		this.showTrainingsTeaser();
	}

	_readConfiguration() {
		super._readConfiguration();
		this.mode = this.getMode();
		this.config = this.$ctx.data('config');
		this._api = this.config.api;
		this.isB2BComponent = this.config.b2b === ("True" || "true") ? true : false;
		this.advertisigKey = this.config.advertisingKey;
		this._getFilterData();
	}

	_addListeners() {
		super._addListeners();
	}

	_handleEventDataSuccess(data) {
		this._clearWrapper();

		if (data && data.length > 0) {
			this.hideNoResultElem(true);
			this.hideList(false);
			this._data = data;
			this._data = this._data.sort((x, y) => parseFloat(x.location.distance) - parseFloat(y.location.distance));
			this._createResultItems();
		} else {
			this.hideNoResultElem(false);
			this.hideList(true);
		}
		T.Utils.View.stopLoader();
	}

	showTrainingsTeaser() {
		const drivingSaftyModel = this.getModelFromStore();
		if (drivingSaftyModel.training && drivingSaftyModel.training.Name) {
			this.querySelector('.js-training-name').innerText = drivingSaftyModel.training.Name;
		}

		if (drivingSaftyModel.training && drivingSaftyModel.training.Desc) {
			this.querySelector('.js-training-desc').innerText = drivingSaftyModel.training.Desc;
		}
		if (drivingSaftyModel.training && drivingSaftyModel.training.Duration) {
			this.querySelector('.js-training-duration').classList.remove('h-hidden');
			this.querySelector('.js-training-duration').innerText = this.getDurationText(drivingSaftyModel.training.Duration);
		}
		if (drivingSaftyModel.training && drivingSaftyModel.training.Vehicle) {
			this.querySelector('.js-training-car').classList.remove('h-hidden');
			this.querySelector('.js-training-car').innerText = this.getVehicleText(drivingSaftyModel.training.Vehicle, drivingSaftyModel.filter.VehicleType);
		}
	}

	getDurationText(duration) {
		return duration === 1 ? 'Ganztageskurs' : 'Halbtageskurs';
	}

	getVehicleText(vehicle, vehicleType) {
		if (vehicleType === "2") {
			return vehicle === 1 ? 'Eigene Maschine' : 'Gestellte Maschine';
		} else {
			return vehicle === 1 ? 'Eigenes Fahrzeug' : 'Gestelltes Fahrzeug';
		}
	}

	_requestData() {
		if (this.checkPreconditions()) {
			T.Utils.View.startLoader();
			this._clearWrapper();
			const trainingModel = this.getTrainingModelFromStore();
			this._requestPayload.trainingID = parseInt(trainingModel.Id);
			if (this.isB2BComponent) {
				this._requestPayload.b2b = true;
			}
			const request = {
				method: 'POST',
				data: this._requestPayload,
				url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this._api),
				headers: { "Ocp-Apim-Subscription-Key": T.Utils.Application.getApiMSubscriptionKey() },
				contentType: "application/x-www-form-urlencoded;charset=utf-8"
			};
			T.Utils.Ajax.fragment(request, this._handleEventDataSuccess.bind(this), this._handleEventDataFailed.bind(this));
		} else if (!this.isExperienceEditor()) {
			const url = this.updateUrlWithCampaignInfo(this.config.trainingUrl);
			T.Utils.Helper.routeToUrl(url);
		}
	}

	checkPreconditions() {
		const trainingModel = this.getTrainingModelFromStore();
		return !!(trainingModel && trainingModel.Id);
	}

	_clearWrapper() {
		this._$resultWrapper.empty();
	}

	_createResultItems() {
		for (let i = 0; i < this._data.length; i++) {
			const itemData = this._data[i];
			if (itemData) {
				const item = this._createLocationResultItem(itemData);
				this._appendResultItemToView(item, itemData);
			}
		}
	}

	_appendResultItemToView($resultItem, itemData) {
		if (itemData) {
			this._$resultWrapper.append($resultItem);
			this._$resultWrapper.toggleClass("h-hidden", false);
			T.Utils.Helper.initAdditionalModules(this.$ctx);
		}
	}

	_registerButtonListeners($resultItem, itemData) {
		if ($resultItem.find(this.btnLocation)) {
			$resultItem.find(this.btnLocation).on('click', this.handleLocationSearchClick.bind(this, itemData));
		}
		if ($resultItem.find(this.btnAppointment)) {
			$resultItem.find(this.btnAppointment).on('click', this.handleAppointmentSearchClick.bind(this, itemData));
		}

		if (!this.isB2BComponent) {
			if (T.Utils.Helper.isNullUndefinedOrEmpty(itemData, this.bookigUrlName)) {
				const $couponLink = $resultItem.find(this.couponSelector);
				const eventData = {
					eventCategory: 'Funnel',
					eventAction: 'Standortauswahl | button_click',
					eventLabel: 'Gutschein kaufen',
					// eventValue: itemData.location.name //NOSONAR //will be implemented soon
				};
				T.Utils.Tracking.updateEventClickData($couponLink, eventData);
				let couponLink = itemData.bookingUrl.coupon;
				if (T.Utils.Helper.isNullUndefinedOrEmpty(this.advertisigKey)) {
					couponLink = T.Utils.Helper.updateUrlParameter({ advertisingKey: this.advertisigKey }, couponLink);
				}
				$couponLink.attr('href', couponLink);
			} else {
				$resultItem.find(this.couponSelector).addClass('h-hidden');
			}
		}
	}

	_displayButtons($resultItem) {
		$resultItem.find(".js-btn-appointment").toggleClass("h-hidden", false);
		$resultItem.find(".js-btn-location").toggleClass("h-hidden", true);
		if (!this.isB2BComponent) {
			$resultItem.find(".js-btn-coupon").toggleClass("h-hidden", false);
		}
	}
}
window.customElements.define('driving-safety-location-list', DrivingSafetyLocationList);