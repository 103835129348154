/// <reference path="../../../atoms/DrivingSafetyBase/js/drivingsafetybase.webc.js" />

/**
 * DrivingSafetyResultListBase, Base Class for all DrivingSafety Result Lists
 *
 * @author  <timo.mueller@mtc.berlin>, <marc.radziwill@merkle.com>
 * @class DrivingSafetyResultListBase
*/

// eslint-disable-next-line no-undef
class DrivingSafetyResultListBase extends DrivingSafetyBase {

	_requestPayload = {};

	static get observedAttributes() { return ["data-filter"]; }

	sortSelect = '.js-search-option';
	hideOnNoResult = '.js-hide-on-noresult';
	btnAppointment = '.js-btn-appointment';
	btnLocation = '.js-btn-location';
	couponSelector = '.js-btn-coupon';
	bookigUrlName = 'bookingUrl.coupon';

	_connectedCallback() {
		T.Utils.Application.init();
		this.$ctx = jQuery(this);
		this._readConfiguration();
		this._addListeners();
		this._requestData();
	}

	_disconnectedCallback() {
		if (this._isConnected) {
			this.$ctx.find(this.sortSelect).off('change', this._searchChangeListener.bind(this));
			this.$ctx.find(this.btnLocation).off('click', this.handleLocationSearchClick.bind(this));
			this.$ctx.find(this.btnAppointment).off('click', this.handleAppointmentSearchClick.bind(this));
		}
	}

	attributeChangedCallback(name, oldValue, newValue) {
		if (this._isConnected && name === "data-filter" && oldValue !== newValue) {
			this._getFilterData();
			this._requestData();
		}
	}

	_readConfiguration() {
		this.$noResult = this.$ctx.find('.js-no-results');
		this.$hideOnNoResultElems = this.$ctx.find(this.hideOnNoResult);
		this._$resultWrapper = this.$ctx.find('.js-result-wrapper');
		this._$resultItemTemplate = jQuery(this.$ctx.find(".js-result-template").html());
		this.lightboxContents = this.$ctx.data('lightbox')?.infobuttonMapping ?? {};
	}

	_getFilterData() {
		if (this.dataset.filter !== undefined) {
			this._requestPayload = JSON.parse(this.dataset.filter);
		}
		if (this.isB2BComponent) {
			this._requestPayload.b2b = true;
		}
		if (this._marketingTag) {
			this._requestPayload.marketingTag = this._marketingTag;
		}
	}

	clearList() {
		this._data = [];
	}

	_addListeners() {
		if (this.$ctx.find(this.sortSelect)) {
			this.$ctx.find(this.sortSelect).on('change', this._searchChangeListener.bind(this));
		}
	}

	_searchChangeListener(e) {
		e.preventDefault();
		const sortType = jQuery(this.sortSelect).val();
		this._$resultWrapper.empty();
		if (this._data) {
			if (sortType === 'p_up') {
				this._data = this._data.sort((x, y) => parseFloat(x.training.minPrice) - parseFloat(y.training.minPrice));
			} else if (sortType === 'd_up') {
				this._data = this._data.sort((x, y) => parseFloat(x.location.distance) - parseFloat(y.location.distance));
			}
			this._createResultItems();
		}
	}


	_requestData() {
		T.Utils.View.startLoader();

		const request = {
			method: 'POST',
			data: JSON.stringify(this._requestPayload),
			url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this._apiGetTrainingTypes),
			headers: { "Ocp-Apim-Subscription-Key": T.Utils.Application.getApiMSubscriptionKey() }
		};
		T.Utils.Ajax.fragment(request, this._handleEventDataSuccess.bind(this), this._handleEventDataFailed.bind(this));
	}


	_clearWrapper() { }

	_handleEventDataSuccess(data) {
		this._clearWrapper();
		if (data && data.length > 0) {
			this.hideNoResultElem(true);
			this._data = data;
			this._createResultItems();
		} else {
			this.hideNoResultElem(false);
		}
		T.Utils.View.stopLoader();
	}

	hideNoResultElem(hide) {
		this.$noResult.toggleClass('h-hidden', hide);
	}

	hideList(hide) {
		this.$hideOnNoResultElems.each((idx, element) => {
			jQuery(element).toggleClass('h-hidden', hide);
		});
	}

	_handleEventDataFailed() {
		this._clearWrapper();
		T.Utils.View.stopLoader();
		T.Utils.View.showGeneralErrorLayer();
	}


	_createResultItems() {
		for (let i = 0; i < this._data.length; i++) {
			const itemData = this._data[i];
			if (itemData) {
				const item = this._createResultItem(itemData);
				this._appendResultItemToView(item, itemData);
			}
		}
	}

	_createResultItem(itemData) {
		const $resultItem = this._$resultItemTemplate.clone();
		$resultItem.find('.js-result-name').text(itemData.training.name);
		$resultItem.find('.js-result-subheadline').text(itemData.training.headline);
		$resultItem.find('.js-result-infotext').text(itemData.training.description);
		let price = Number(itemData.training.minPrice.replace(',', '.'));
		if (price % 1 !== 0) {
			price = price.toFixed(2).toString().replace('.', ',');

		}
		$resultItem.find('.js-result-price').text(price.toLocaleString('de-DE'));

		const $resultImage = $resultItem.find('.js-result-image');
		$resultImage.attr('src', itemData.training.pictureLink);
		$resultImage.attr('data-src', itemData.training.pictureLink);
		$resultImage.attr('alt', itemData.training.pictureAltTag);

		if (itemData.training.hasBgGrant && itemData.training.hasBgGrant.length > 0) {
			this._fillAndShowResultData($resultItem, ".js-result-bg-grant", itemData.training.hasBgGrant);
		}
		if (itemData.training.marketingTag && itemData.training.marketingTag.length > 0) {
			this._fillAndShowResultData($resultItem, ".js-result-marketing", itemData.training.marketingTag);
		}
		if (itemData.training.experienceTag && itemData.training.experienceTag.length > 0) {
			this._fillAndShowResultData($resultItem, ".js-result-experience", itemData.training.experienceTag);
		}
		if (itemData.training.car && itemData.training.car.value && itemData.training.car.value.length > 0) {
			this._fillAndShowResultData($resultItem, ".js-result-car", itemData.training.car.value);
		}
		if (itemData.training.duration && itemData.training.duration.value && itemData.training.duration.value.length > 0) {
			this._fillAndShowResultData($resultItem, ".js-result-duration", itemData.training.duration.value);
		}
		if (itemData?.training?.id) {
			const infoBoxContent = this.lightboxContents[itemData.training.id];
			if (infoBoxContent) {
				this._addInfoboxContent($resultItem, infoBoxContent, itemData);
			}
		}

		this._displayButtons($resultItem);
		this._registerButtonListeners($resultItem, itemData);
		T.Utils.Helper.initAdditionalModules(this.$ctx);

		return $resultItem;
	}

	_createLocationResultItem(itemData) {
		const $resultItem = this._$resultItemTemplate.clone();
		$resultItem.find('.js-result-name').text(itemData.location.name);
		$resultItem.find('.js-result-infotext').text(itemData.location.shortDescription);
		let price = Number(itemData.training.minPrice.replace(',', '.'));
		if (price % 1 !== 0) {
			price = price.toFixed(2).toString().replace('.', ',');
		}
		$resultItem.find('.js-result-price').text(price.toLocaleString('de-DE'));
		$resultItem.find('.js-result-subheadline').text(itemData.location.headline);

		const $resultImage = $resultItem.find('.js-result-image');
		$resultImage.attr('src', itemData.location.pictureLink);
		$resultImage.attr('data-src', itemData.location.pictureLink);
		$resultImage.attr('alt', itemData.location.pictureAltTag);

		if (itemData.location.distance) {
			$resultItem.find('.js-result-distance-wrapper').toggleClass('h-hidden', false);
			this._fillAndShowResultData($resultItem, ".js-result-distance", itemData.location.distance);
		}
		if (itemData.training.countTrainingEvents) {
			$resultItem.find('.js-sub-available').toggleClass('h-hidden', false);
			$resultItem.find('.js-sub-available span').text(itemData.training.countTrainingEvents);
		}

		if (itemData.location.catering && itemData.location.catering.length > 0) {
			const $catering = $resultItem.find('.js-result-catering');
			$catering.find('.mm-headline').html(itemData.location.catering);
			$catering.toggleClass('h-hidden', false);
		}

		if (!this.isB2BComponent) {
			if (T.Utils.Helper.isNullUndefinedOrEmpty(itemData, this.bookigUrlName)) {
				let couponLink = itemData.bookingUrl.coupon;
				if (T.Utils.Helper.isNullUndefinedOrEmpty(this.advertisigKey)) {
					couponLink = T.Utils.Helper.updateUrlParameter({ advertisingKey: this.advertisigKey }, couponLink);
				}
				$resultItem.find(this.couponSelector).attr('href', couponLink);
			} else {
				$resultItem.find(this.couponSelector).addClass('h-hidden');
			}
		}

		if (itemData?.location?.locationID) {
			const infoBoxContent = this.lightboxContents[itemData.location.locationID];
			if (infoBoxContent) {
				this._addInfoboxContent($resultItem, infoBoxContent);
			}
		}

		this._displayButtons($resultItem);
		this._registerButtonListeners($resultItem, itemData);
		T.Utils.Helper.initAdditionalModules(this.$ctx);

		return $resultItem;
	}

	_addInfoboxContent($resultItem, infoBoxContent, itemData) {
		$resultItem.find('.js-infobox').toggleClass('h-hidden', false);
		if (itemData) {
			const $infoBoxButton = $resultItem.find('.js-infobox').find('button');
			$infoBoxButton.on('click', this.trackTrainingInfobuttonClick.bind(this, itemData));
		}
		const $infoBoxContent = $resultItem.find('.js-infobox-content');
		$infoBoxContent.html(infoBoxContent);
		$infoBoxContent.toggleClass("h-hidden", false);
	}

	_registerButtonListeners($resultItem, itemData) {
		if ($resultItem.find(this.btnLocation)) {
			$resultItem.find(this.btnLocation).on('click', this.handleLocationSearchClick.bind(this, itemData));
		}
		if ($resultItem.find(this.btnAppointment)) {
			$resultItem.find(this.btnAppointment).on('click', this.handleAppointmentSearchClick.bind(this, itemData));
		}

		if (!this.isB2BComponent) {
			if (T.Utils.Helper.isNullUndefinedOrEmpty(itemData, this.bookigUrlName)) {
				let couponLink = itemData.bookingUrl.coupon;
				if (this.advertisigKey !== null) {
					couponLink = T.Utils.Helper.updateUrlParameter({ advertisingKey: this.advertisigKey }, couponLink);
				}
				const $couponLink = $resultItem.find(this.couponSelector);
				$couponLink.attr('href', couponLink);
			} else {
				$resultItem.find(this.couponSelector).addClass('h-hidden');
			}
		}
	}

	handleLocationSearchClick(itemData, e) {
		e.preventDefault();
		this.trackLocationClick(itemData);
		const training = {
			Name: itemData.training.name,
			Id: itemData.training.id,
			Link: itemData.training.detailLink,
			Desc: itemData.training.headline
		};

		training.Vehicle = itemData?.training?.car?.uid;
		training.Duration = itemData?.training?.duration?.uid;
		this.setTrainingModelToStore(training);

		this.setMode('location');
		const url = this.updateUrlWithCampaignInfo(this.config.locationPage);
		T.Utils.Helper.routeToUrl(url);
	}

	trackLocationClick(itemData) {
		const eventData = {
			eventCategory: 'Funnel',
			eventAction: 'Trainingsauswahl | funnel_click',
			eventLabel: 'Standort suchen',
			product: 'Fahrsicherheit',
			productVariant: this.isB2BComponent ? 'B2B' : 'B2C',
			funnelStepInhalt: itemData.training.name
		};

		T.Utils.Tracking.pushData({
			eventData: eventData,
			event: 'click'
		});
	}

	trackTrainingInfobuttonClick(itemData) {
		const eventData = {
			eventCategory: 'Content',
			eventAction: 'Trainingsauswahl | info_click',
			eventLabel: itemData.training.name,
			product: 'Fahrsicherheit',
			productVariant: this.isB2BComponent ? 'B2B' : 'B2C'
		};

		T.Utils.Tracking.pushData({
			eventData: eventData,
			event: 'click'
		});
	}

	handleAppointmentSearchClick(itemData, e) {
		e.preventDefault();
		this.trackAppointmentClick(itemData);
		if (!this.isB2BComponent) {
			const locations = this._data.map((item) => {
				return {
					id: item.location.locationID,
					name: item.location.name
				};
			});
			const uniqueLocations = [];
			const idsSeen = {};
			locations.filter(item => {
				if (!idsSeen[item.id]) {
					idsSeen[item.id] = true;
					uniqueLocations.push(item);
				}
			});

			const locationObj = {
				Id: itemData.location.locationID,
				Name: itemData.location.name,
				Link: itemData.detailLink,
				sublocations: uniqueLocations
			};


			if (T.Utils.Helper.isNullUndefinedOrEmpty(itemData, 'bookingUrl.coupon')) {
				locationObj.CouponLink = itemData.bookingUrl.coupon;
			}

			this.setLocationModelToStore(locationObj);
			this.setMode('appointment');
			const url = this.updateUrlWithCampaignInfo(this.config.appointmentUrl);
			T.Utils.Helper.routeToUrl(url);
		} else {
			const url = itemData.bookingUrl.training;
			T.Utils.Helper.routeToUrl(url);
		}

	}

	trackAppointmentClick(itemData) {
		const eventData = {
			eventCategory: 'Funnel',
			eventAction: 'Standortauswahl | funnel_click',
			eventLabel: 'Termin finden',
			product: 'Fahrsicherheit',
			productVariant: this.isB2BComponent ? 'B2B' : 'B2C',
			funnelStepInhalt: itemData.location.name
		};

		T.Utils.Tracking.pushData({
			eventData: eventData,
			event: 'click'
		});
	}

	_appendResultItemToView() { }

	_fillAndShowResultData($resultItem, selector, text) {
		const $marketingTag = $resultItem.find(selector);
		$marketingTag.text(text);
		$marketingTag.toggleClass('h-hidden', false);
	}

	setLocationModelToStore(locationModel) {
		const drivingSafetyModel = this.getModelFromStore();
		drivingSafetyModel.location = locationModel;
		T.Utils.Store.set(this.SESSION_KEY, drivingSafetyModel, T.Utils.Store.SESSION);
	}

	setTrainingModelToStore(trainingModel) {
		const drivingSafetyModel = this.getModelFromStore();

		drivingSafetyModel.training = trainingModel;
		T.Utils.Store.set(this.SESSION_KEY, drivingSafetyModel, T.Utils.Store.SESSION);
	}
}
window.customElements.define('driving-safety-resultlistbase', DrivingSafetyResultListBase);

if (typeof module !== "undefined" && module.exports) {
	module.exports = DrivingSafetyResultListBase;
}