/// <reference path="../../../../assets/js/webc/basiscomponent.webc.js" />

/**
 * DrivingSafetyBase, Base Class for all DrivingSafety Components
 *
 * @author  <marc.radziwill@merkle.com>
 * @class DrivingSafetyBase
*/

// eslint-disable-next-line no-unused-vars
class DrivingSafetyBase extends BasisComponent {

	SESSION_KEY = 'DrivingSafety';

	apiReverseGeocoding = '';

	getModelFromStore() {
		const inputModel = T.Utils.Store.get(this.SESSION_KEY, T.Utils.Store.SESSION);
		return inputModel ? inputModel : { filter: {}, location: {}, training: {} };
	}

	getLocationModelFromStore() {
		const inputModel = this.getModelFromStore();
		return inputModel && inputModel.location ? inputModel.location : {};
	}

	getFilterModelFromStore() {
		const inputModel = this.getModelFromStore();
		return inputModel && inputModel.filter ? inputModel.filter : {};
	}

	getTrainingModelFromStore() {
		const inputModel = this.getModelFromStore();
		return inputModel && inputModel.training ? inputModel.training : {};
	}

	clearModelData() {
		T.Utils.Store.set(this.SESSION_KEY, null, T.Utils.Store.SESSION);
	}

	getMode() {
		const inputModel = this.getModelFromStore();
		return inputModel && inputModel.mode ? inputModel.mode : '';
	}

	isMode(mode) {
		return mode === this.getMode();
	}

	setMode(mode) {
		const inputModel = this.getModelFromStore() || {};
		inputModel.mode = inputModel && inputModel.mode ? inputModel.mode : '';
		inputModel.mode = mode;
		T.Utils.Store.set(this.SESSION_KEY, inputModel, T.Utils.Store.SESSION);
	}

	getClientGeoLocation(successCallback, errorCallback) {
		if (!navigator.geolocation) {
			console.warn("This browser doesn't support geolocation");
			errorCallback();
			return;
		}

		const success = ((position) => {
			const p = T.Utils.Geo.latlonToMercator({ x: position.coords.longitude, y: position.coords.latitude });

			p.x = Math.round(p.x);
			p.y = Math.round(p.y);

			const renderFunc = ((foundAddress) => {
				if (T.Utils.Helper.isEmptyString(foundAddress.Postleitzahl)) {
					errorCallback();
				}
				else {
					successCallback(foundAddress);
				}
			});
			T.Utils.Map.getGeolocationFromApiByCoords(p, renderFunc, errorCallback, this.apiReverseGeocoding);
		});
		navigator.geolocation.getCurrentPosition(success, errorCallback);
	}

	backButtonListener(e) {
		e.preventDefault();
		history.back();
	}

	updateUrlWithCampaignInfo(url) {
		if (url) {
			const campaignIds = T.Utils.Helper.getCampaignUrlParams();
			if (campaignIds && campaignIds.length) {
				const newUrl = T.Utils.Helper.getUrlWithIdParam(url, campaignIds, {});
				if (newUrl !== url) {
					return newUrl;
				}
			}
		}
		return url;
	}
}
if (typeof module !== "undefined" && module.exports) {
	module.exports = DrivingSafetyBase;
}