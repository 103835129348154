/// <reference path="../../../atoms/DrivingSafetyBase/js/drivingsafetybase.webc.js" />

/**
 * DrivingSafetyFilterBase, Base Class for all DrivingSafety Filters
 *
 * @author  <marc.radziwill@merkle.com>
 * @class DrivingSafetyFilterBase
*/

// eslint-disable-next-line no-unused-vars, no-undef
class DrivingSafetyFilterBase extends DrivingSafetyBase {

	requestCnt = 0;

	backButton = '.js-back-btn';

	_connectedCallback() {
		T.Utils.Application.init();
		this.$ctx = jQuery(this);
		this.setMode(this.getAttribute('data-mode'));
		this.readConfig();
		this.registerListeners();
		this.setInitStatus();
		this.updateView();
	}

	_disconnectedCallback() {
		if (this._isConnected) {
			this.$form.off('submit', (e) => { e.preventDefault(); });
			this.off('dataready', this.handleDataReady.bind(this));
			this.$ctx.find('.js-localizeme').off('click', this.useClientGeoLocation.bind(this));
			this.$ctx.find('[name="filter_type"]').off('change', this.handleVehicleType.bind(this));
		}
	}

	readConfig() {
		this.$form = this.$ctx.find('form');
		this.$vehicleTypes = this.$ctx.find('.js-vehicle-types');
		this.$trainingExperience = this.$ctx.find('.js-experience');
		this.$categoryTemplate = jQuery(this.$ctx.find(".js-vehicletype-template").html());
		this.$trainingExperieneceTemplate = jQuery(this.$ctx.find(".js-experience-template").html());
		this.requestedExperiences = this.$trainingExperieneceTemplate.length > 0;
		this.$noBackend = this.$ctx.find(".js-nobackend");
		this.$plzInput = this.$ctx.find(".js-plz-input");
		this.$submitButton = this.$ctx.find('.js-submit');
		this.targetUrl = this.$submitButton.attr('href');

		this.filterComponent = this.querySelector('dsfilter-component');
		this.apiRequests = this.requestedExperiences ? 2 : 1;
		this.config = this.$ctx.data('config') || {};
		this.apiReverseGeocoding = this.config.apiReverseGeocoding;
		this.apiCategory = this.config.apiCategory;
		this.apiTrainingExperience = this.config.apiTrainingExperience;
		this.iconBasePath = this.config.iconBasePath;
		this.iconMapping = this.config.iconMapping;
		this.isB2BComponent = this.config.b2b === ("True" || "true") ? true : false;
	}

	setInitStatus() {
		if (!this.isMode('location')) {
			this.requestCategories();
			if (this.requestedExperiences && !this.isB2BComponent) {
				this.requestExperiences();
			}
		} else {
			this.activateFilter();
		}
	}


	updateView() {
		if (this.isMode('location')) {
			this.$ctx.find('.js-hide-on-location').toggleClass('h-hidden', true);
		} else {
			this.$ctx.find('.js-hide-on-location').toggleClass('h-hidden', false);
		}
	}

	registerListeners() {
		this.$form.on('submit', (e) => { e.preventDefault(); });
		this.on('dataready', this.handleDataReady.bind(this));
		this.$ctx.find('.js-localizeme').on('click', this.useClientGeoLocation.bind(this));
		this.$ctx.find(this.backButton).on('click', this.backButtonListener.bind(this));

	}

	handleDataReady() {
		this.requestCnt++;
		if (this.requestCnt === this.apiRequests && this.filterComponent) {
			this.activateFilter();
		}
	}

	handleVehicleType() { }

	activateFilter() {
		this.filterComponent.removeEvents();
		this.filterComponent.init();
		this._initValidation();
		this.filterComponent.countActiveFilter();
		this.setDataFromStorage();
		this.$ctx.find('[name="filter_type"]').on('change', this.handleVehicleType.bind(this));
		if (Object.keys(this.getFilterModelFromStore()).length) {
			this.emitEvent('dofiler', { firstClick: true });
		}
	}

	_initValidation() {
		const validationOptions = {
			messages: this.$form.data('validation-messages') ? JSON.parse(this.$form.data('validation-messages')) : {},
			rules: {
				'filter_type': {
					required: true
				},
				'plz-input-1': {
					required: true,
					maxlength: 5,
					minlength: 5,
					digits: true
				}
			}
		};
		T.Utils.Validation.init(this.$ctx, this.$form, validationOptions);
	}

	requestCategories() {
		const request = {
			method: 'get',
			url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this.apiCategory),
			headers: { "Ocp-Apim-Subscription-Key": T.Utils.Application.getApiMSubscriptionKey() }
		};
		T.Utils.Ajax.fragment(request, this.handleRequestCategoriesSuccess.bind(this), this.handleRequestFailed.bind(this));
	}

	requestExperiences() {
		const request = {
			method: 'get',
			url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this.apiTrainingExperience),
			headers: { "Ocp-Apim-Subscription-Key": T.Utils.Application.getApiMSubscriptionKey() }
		};
		T.Utils.Ajax.fragment(request, this.handleRequestExperiencesSuccess.bind(this), this.handleRequestFailed.bind(this));

	}


	handleRequestCategoriesSuccess(data) {
		if (data && data.length > 0) {
			if (this.isB2BComponent) {
				data = data.slice(0, 3);
			}
			for (let i = 0; i < data.length; i++) {
				const itemData = data[i];
				if (itemData) {
					this._createCategoriesAndAppendToView(itemData);
				}
			}
		}
		this.emitEvent('dataready');
		T.Utils.View.stopLoader();
	}

	handleRequestExperiencesSuccess(data) {
		if (data && data.length > 0) {
			for (let i = 0; i < data.length; i++) {
				const itemData = data[i];
				if (itemData) {
					this._createExperiencesAndAppendToView(itemData);
				}
			}
		}
		this.emitEvent('dataready');
		T.Utils.View.stopLoader();
	}

	setFilterModelToStore(filterModel) {
		const drivingSaftyModel = T.Utils.Store.get(this.SESSION_KEY, T.Utils.Store.SESSION) || { filter: {} };
		drivingSaftyModel.filter = filterModel;
		T.Utils.Store.set(this.SESSION_KEY, drivingSaftyModel, T.Utils.Store.SESSION);
	}

	setFilterFormModelToStore() {
		const visibleElements = this.$form.find(":input:visible, select:visible");
		const formData = visibleElements.serializeArray();
		const drivingSaftyModel = T.Utils.Store.get(this.SESSION_KEY, T.Utils.Store.SESSION) || { filter: {} };
		drivingSaftyModel.filter = drivingSaftyModel.filter ? drivingSaftyModel.filter : {};
		if (formData) {
			formData.forEach((entry) => {
				if (entry.name === 'filter_type') {
					drivingSaftyModel.filter.VehicleType = entry.value;
				} else if (entry.name === 'plz-input-1') {
					drivingSaftyModel.filter.Postcode = entry.value;
				} else if (entry.name === 'exp_type') {
					drivingSaftyModel.filter.TrainingExpertise = entry.value;
				} else if (entry.name === 'filter_number') {
					drivingSaftyModel.filter.Participants = entry.value;
				} else if (entry.name === 'filter_circle') {
					drivingSaftyModel.filter.Radius = entry.value;
				} else if (entry.name === 'rangestart') {
					drivingSaftyModel.filter.Start = entry.value;
				} else if (entry.name === 'rangeend') {
					drivingSaftyModel.filter.End = entry.value;
				} else if (entry.name === 'add_duration') {
					drivingSaftyModel.filter.Duration = entry.value;
				} else if (entry.name === 'add_vehicle') {
					drivingSaftyModel.filter.Vehicle = entry.value;
				} else if (entry.name === 'add_supply') {
					drivingSaftyModel.filter.Grand = entry.value;
				}
			});
		}
		T.Utils.Store.set(this.SESSION_KEY, drivingSaftyModel, T.Utils.Store.SESSION);
	}

	setElementValue(selector, value) {
		const element = this.querySelector(selector);
		if (element) {
			element.value = value;
		}
	}

	setCheckboxValue(selector, value) {
		const element = this.querySelector(selector);
		if (element) {
			element.checked = value;
		}
	}

	clickElement(selector) {
		const element = this.querySelector(selector);
		if (element) {
			element.click();
		}
	}

	setDataFromStorage() {
		const filterModel = this.getFilterModelFromStore();
		if (filterModel) {
			if (filterModel.VehicleType) {
				this.setCheckboxValue(`input[name="filter_type"][value="${filterModel.VehicleType}"]`, true);
			}
			if (filterModel.TrainingExpertise) {
				this.setCheckboxValue(`input[name="exp_type"][value="${filterModel.TrainingExpertise}"]`, true);
				this.openAdditionalFields();
			}
			if (filterModel.Participants) {
				this.setElementValue(`select[name="filter_number"]`, filterModel.Participants);
			}
			if (filterModel.Radius) {
				this.setElementValue(`select[name="filter_circle"]`, filterModel.Radius);
			}
			if (filterModel.Start) {
				this.setElementValue(`input[name="rangestart"]`, filterModel.Start);
			}
			if (filterModel.End) {
				this.setElementValue(`input[name="rangeend"]`, filterModel.End);
			}
			if (filterModel.Duration) {
				this.setCheckboxValue(`input[name="add_duration"][value="${filterModel.Duration}"]`, true);
				this.openAdditionalFields();
			}
			if (filterModel.Vehicle) {
				this.setCheckboxValue(`input[name="add_vehicle"][value="${filterModel.Vehicle}"]`, true);
				this.openAdditionalFields();
			}
			if (filterModel.Grand) {
				this.setCheckboxValue(`input[name="add_supply"]`, filterModel.Grand);
			}

			if (filterModel.Postcode && this.$plzInput) {
				this.$plzInput.val(filterModel.Postcode);
			}
		}
	}

	openAdditionalFields() {
		const $additionalFields = this.$ctx.find('.js-additional-toggle');
		this.alreadyOpen = false;
		if (!this.alreadyOpen && $additionalFields && $additionalFields.hasClass('is-open')) {
			$additionalFields.click();
			this.alreadyOpen = true;
		}
	}


	handleRequestFailed() {
		this.$noBackend.toggleClass('h-hidden', false);
		this.$form.toggleClass('h-hidden', true);
		T.Utils.View.stopLoader();
	}

	_createCategoriesAndAppendToView(itemData) {
		const $category = this.$categoryTemplate.clone();
		$category.find('.js-category-name').text(itemData.value);
		const id = "filter_type_";
		const uid = itemData.uid;
		const idValue = `${id}${uid}`;
		$category.find('input').prop('id', idValue).prop('value', uid);
		$category.find('label').prop('for', idValue);
		if (this.iconMapping && !T.Utils.Helper.isEmptyString(this.iconMapping[uid])) {
			$category.find('img').prop('src', `${this.iconBasePath}${this.iconMapping[uid]}`);
		}

		this.$vehicleTypes.append($category);
	}

	_createExperiencesAndAppendToView(itemData) {
		const $exp = this.$trainingExperieneceTemplate.clone();
		const expLabel = $exp.find('label');
		expLabel.text(itemData.value);
		const id = "exp_type_";
		const uid = itemData.uid;
		const idValue = `${id}${uid}`;
		$exp.find('input').prop('id', idValue).prop('value', uid);
		expLabel.prop('for', idValue);
		this.$trainingExperience.append($exp);
	}

	useClientGeoLocation() {
		this._showAutolocationError(false);
		this.getClientGeoLocation(this.getClientGeoLocationSuccess.bind(this), this.getClientGeoLocationError.bind(this));
	}

	getClientGeoLocationSuccess(foundAddress) {
		this.$plzInput.val(foundAddress.Postleitzahl);

		if (this.$form && this.$form.validation) {
			this.$form.validation.element(this.plzInput);
			this.$plzInput.focus();
		}
	}

	getClientGeoLocationError() {
		this._showAutolocationError(true);
	}

	_showAutolocationError(show) {
		this.$ctx.find('.js-auto-location-err').toggleClass('h-hidden', !show);
	}
}

if (typeof module !== "undefined" && module.exports) {
	module.exports = DrivingSafetyFilterBase;
}