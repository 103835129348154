/// <reference path="../../../atoms/DrivingSafetyAppointmentLinkBase/js/drivingsafetyappointmentlinkbase.webc.js" />

/**
 * DrivingSafetyAppointmentLocationLink component to find appointments for one training.
 *
 * @author <marc.radziwill@merkle.com>
 * @class DrivingSafetyAppointmentLocationLink
*/

// eslint-disable-next-line no-undef
class DrivingSafetyAppointmentLocationLink extends DrivingSafetyAppointmentLinkBase {

	_dataAvailable = false;

	localizemeSelector = '.js-localizeme';

	findAppointmentSelector = '.js-find-appointment';

	_readConfiguration() {
		super._readConfiguration();
		this.isB2BComponent = this.config.b2b === ("True" || "true") ? true : false;
		this.$form = this.$ctx.find('form');
		this.contentWrapper = this.querySelector('.js-content-wrapper');
		const withoutDataElement = this.querySelector('.js-without-data');
		const withDataElement = this.querySelector('.js-with-data');
		this.withoutDataTemplate = withoutDataElement ? withoutDataElement.innerHTML : null;
		this.withDataTemplate = withDataElement ? withDataElement.innerHTML : null;
		this.apiReverseGeocoding = this.config.apiReverseGeocoding;
		this.$findAppointmentButton = this.$ctx.find(this.findAppointmentSelector);
	}

	_disconnectedCallback() {
		if (this._isConnected) {
			if (this.$ctx.find(this.localizemeSelector)) {
				this.$ctx.find(this.localizemeSelector).off('click', this.useClientGeoLocation.bind(this));
			}
			if (this.$findAppointmentButton) {
				this.$findAppointmentButton.off('click', this.searchLocationAndRedirect.bind(this));
			}
		}
	}

	_registerListeners() {
		if (this.$ctx.find(this.localizemeSelector)) {
			this.$ctx.find(this.localizemeSelector).on('click', this.useClientGeoLocation.bind(this));
		}
		if (this.$findAppointmentButton) {
			this.$findAppointmentButton.on('click', this.searchLocationAndRedirect.bind(this));
		}
	}

	searchLocationAndRedirect(e) {
		e.preventDefault();
		if (this.$form.valid()) {
			const request = {
				method: 'GET',
				url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), `${this.config.api}/${this.config.trainingId}`),
				headers: { "Ocp-Apim-Subscription-Key": T.Utils.Application.getApiMSubscriptionKey() }
			};
			T.Utils.Ajax.fragment(request, this._handleTrainingDataSuccess.bind(this), this._handleTrainingDataFailed.bind(this));
		}
	}
	_handleTrainingDataSuccess(data) {
		if (data.constructor === Object) {
			const drivingSafetyModel = this.getModelFromStore();
			drivingSafetyModel.filter.Postcode = this.$plzInput.val();
			drivingSafetyModel.filter.VehicleType = data.training.category.uid;
			drivingSafetyModel.training.Id = data.training.id;
			drivingSafetyModel.training.Name = data.training.name;
			T.Utils.Store.set(this.SESSION_KEY, drivingSafetyModel, T.Utils.Store.SESSION);
			const url = this.updateUrlWithCampaignInfo(this.config.locationUrl);
			T.Utils.Helper.routeToUrl(url);
		} else {
			this.querySelector('.js-training-err').classList.remove('h-hidden');
		}
	}
	_handleTrainingDataFailed() {
		this.querySelector('.js-training-err').classList.remove('h-hidden');
	}

	_prepareComponent() {
		if (this.checkPreconditions()) {
			this._dataAvailable = true;
		}
	}

	setDataFromStore() {
		const filterModel = this.getFilterModelFromStore();

		if (filterModel.Postcode) {
			this.$plzInput.val(filterModel.Postcode);
		}
	}

	checkPreconditions() {
		const trainingsModel = this.drivingSafetyModel ? this.drivingSafetyModel.training : undefined;
		const locationModel = this.drivingSafetyModel ? this.drivingSafetyModel.location : undefined;

		if (T.Utils.Helper.isNullUndefinedOrEmpty(trainingsModel) && T.Utils.Helper.isNullUndefinedOrEmpty(locationModel) && T.Utils.Helper.isNullUndefinedOrEmpty(locationModel, 'Id')) {
			const sessionTrainingId = trainingsModel.Id;
			const sessionLocationId = parseInt(locationModel.Id);

			if (T.Utils.Helper.isNullUndefinedOrEmpty(this.config, 'trainingId')) {
				this._trainingId = parseInt(this.config.trainingId);
			}
			if (this._trainingId !== "" && sessionTrainingId !== "" && T.Utils.Helper.isNullUndefinedOrEmpty(sessionTrainingId) && T.Utils.Helper.isNullUndefinedOrEmpty(sessionLocationId) && this._trainingId === sessionTrainingId) {
				return true;
			}
		}
		return false;
	}

	_render() {
		if (this._dataAvailable && !this.isB2BComponent) {
			this.contentWrapper.innerHTML = this.withDataTemplate;
			this._readConfiguration();
			if (this.appointmentLink) {
				const url = this.updateUrlWithCampaignInfo(this.config.appointmentUrl ? this.config.appointmentUrl : window.location.href);
				this.appointmentLink.setAttribute('href', url);
			}

			if (this.couponLink && T.Utils.Helper.isNullUndefinedOrEmpty(this.drivingSafetyModel.location.CouponLink)) {
				let link = T.Utils.Helper.isNullUndefinedOrEmpty(this.drivingSafetyModel.location.CouponLink) ? this.drivingSafetyModel.location.CouponLink : window.location.href;
				if (T.Utils.Helper.isNullUndefinedOrEmpty(this.advertisigKey)) {
					link = T.Utils.Helper.updateUrlParameter({ advertisingKey: this.advertisigKey }, link);
				}
				this.couponLink.setAttribute('href', link);
			} else {
				this.couponLink.classList.add('h-hidden');
			}
		} else {
			this.contentWrapper.innerHTML = this.withoutDataTemplate;
			this.$plzInput = this.$ctx.find('.js-plz-input');
			this.$findAppointmentButton = this.$ctx.find(this.findAppointmentSelector);
			this.setDataFromStore();
			this._initValidation();
		}
		this._registerListeners();
	}

	_initValidation() {
		const validationOptions = {
			messages: this.$form.data('validation-messages') ? JSON.parse(this.$form.data('validation-messages')) : {},
			rules: {
				'plz-input-1': {
					required: true,
					maxlength: 5,
					minlength: 5,
					digits: true
				}
			}
		};
		T.Utils.Validation.init(this.$ctx, this.$form, validationOptions);
	}

	useClientGeoLocation() {
		this._showAutolocationError(false);
		this.getClientGeoLocation(this.getClientGeoLocationSuccess.bind(this), this.getClientGeoLocationError.bind(this));
	}

	getClientGeoLocationSuccess(foundAddress) {
		this.city = foundAddress.Stadt;
		this.$plzInput.val(foundAddress.Postleitzahl);

		if (this.$form && this.$form.validation) {
			this.$form.validation.element(this.plzInput);
			this.$plzInput.focus();
		}
		this.drivingSafetyModel = this.getModelFromStore();
	}

	getClientGeoLocationError() {
		this._showAutolocationError(true);
	}

	_showAutolocationError(show) {
		this.$ctx.find('.js-auto-location-err').toggleClass('h-hidden', !show);
	}

}
window.customElements.define('driving-safety-appointment-location-link', DrivingSafetyAppointmentLocationLink);