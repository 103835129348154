/// <reference path="../../../atoms/DrivingSafetyBase/js/drivingsafetybase.webc.js" />

/**
 * DrivingSafetyTrainingLocationSearch Search for driving safety training locations.
 *
 * @author  <riccardo.jung@merkle.com>
 * @class DrivingSafetyTrainingLocationSearch
*/

// eslint-disable-next-line no-undef
class DrivingSafetyLocationSearch extends DrivingSafetyBase {
	constructor() {
		super();

	}

	_connectedCallback() {
		T.Utils.Application.init();
		this.$ctx = jQuery(this);
		this.readConfig();
		this.initValidation();
		this.registerListeners();
		this.requestLocations();
	}

	_disconnectedCallback() {
		if (this._isConnected) {
			this.$ctx.find('.js-localizeme').off('click', this.useClientGeoLocation.bind(this));
			this.$submitButton.off('click', this.submitForm.bind(this));
		}
	}

	readConfig() {
		this.$form = this.$ctx.find('form');
		this.$noBackend = this.$ctx.find(".js-nobackend");
		this.$resultTemplate = jQuery(this.$ctx.find(".js-result-template").html());
		this.$plzInput = this.$ctx.find(".js-plz-input");
		this.$submitButton = this.$ctx.find('.js-submit');
		this.$resultContainer = this.$ctx.find('.js-result-container');

		this.config = this.$ctx.data('config') || {};
		this.isB2BComponent = this.config.b2b === ("True" || "true") ? true : false;
		this.apiReverseGeocoding = this.config.apiReverseGeocoding;
		this.apiGetLocations = this.config.apiGetLocations;
	}

	initValidation() {
		const validationOptions = {
			messages: this.$form.data('validation-messages') ? JSON.parse(this.$form.data('validation-messages')) : {},
			rules: {
				'plz-input-1': {
					required: false,
					maxlength: 5,
					minlength: 5,
					digits: true
				}
			}
		};
		T.Utils.Validation.init(this.$ctx, this.$form, validationOptions);
	}

	registerListeners() {
		this.$ctx.find('.js-localizeme').on('click', this.useClientGeoLocation.bind(this));
		this.$submitButton.on('click', this.submitForm.bind(this));
	}

	requestLocations(zipCode) {
		T.Utils.View.startLoader();
		this.clearWrapper();
		let payload = {
			sortBy: "zip"
		};

		if (zipCode) {
			payload = {
				zip: zipCode,
				radius: 200,
				sortBy: "distance"
			};
		}

		if (this.isB2BComponent) {
			payload.b2b = true;
		}

		const request = {
			method: 'POST',
			url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this.apiGetLocations),
			headers: { "Ocp-Apim-Subscription-Key": T.Utils.Application.getApiMSubscriptionKey() },
			data: payload,
			contentType: "application/x-www-form-urlencoded;charset=utf-8"
		};
		T.Utils.Ajax.fragment(request, this.handleRequestSuccess.bind(this), this.handleRequestFailed.bind(this));
	}

	handleRequestSuccess(data) {
		this._showAutolocationError(false);
		if (data && data.length > 0) {
			const locationList = [];
			for (let i = 0; i < data.length; i++) {
				const itemData = data[i];
				if (itemData && itemData.location) {
					const headline = itemData.location.adacTrainingFacility;
					if (headline && !locationList.includes(headline)) {
						locationList.push(headline);
						this.createResultsAndAppendToView(itemData.location);
					}
				}
			}
		}
		T.Utils.View.stopLoader();
	}

	createResultsAndAppendToView(locationData) {
		const $resultItem = this.$resultTemplate.clone();

		const headline = locationData.adacTrainingFacility;
		$resultItem.find('.js-headline').text(headline);

		const zip = locationData.zip;
		const locationName = locationData.name;
		if (zip && locationName) {
			$resultItem.find('.js-location').text(`${zip} ${locationName}`);
		}

		const imgLink = locationData.pictureLink;

		if (imgLink) {
			$resultItem.find('.js-img').prop('src', imgLink);
			const imgAlt = locationData.pictureAltTag;
			if (imgAlt) {
				$resultItem.find('.js-img').prop('alt', imgAlt);
			}
		}
		else {
			$resultItem.find('.js-img-wrapper').toggleClass('h-hidden', true);
		}

		const distance = locationData.distance;
		if (distance) {
			$resultItem.find('.js-distance').text(distance);
		}
		else {
			$resultItem.find('.js-distance-wrapper').toggleClass('h-hidden', true);
		}

		const detailLink = locationData.detailLink;
		if (detailLink) {
			const $locationLink = $resultItem.find('.js-location-link');
			$locationLink.attr('href', detailLink);
		}

		if (locationData.shortDescription) {
			$resultItem.find('.js-description').html(locationData.shortDescription);
		}

		this.$resultContainer.append($resultItem);
	}

	handleRequestFailed() {
		this.$noBackend.toggleClass('h-hidden', false);
		T.Utils.View.stopLoader();
	}

	clearWrapper() {
		this.$resultContainer.empty();
	}

	useClientGeoLocation() {
		this._showAutolocationError(false);
		this.getClientGeoLocation(this.getClientGeoLocationSuccess.bind(this), this.getClientGeoLocationError.bind(this));
	}

	getClientGeoLocationSuccess(foundAddress) {
		if (T.Utils.Helper.isEmptyString(foundAddress.Postleitzahl)) {
			this._showAutolocationError(true);
		} else {

			this.$plzInput.val(foundAddress.Postleitzahl);

			if (this.$form && this.$form.validation) {
				this.$form.validation.element(this.plzInput);
				this.$plzInput.focus();
			}
		}
	}

	getClientGeoLocationError() {
		this._showAutolocationError(true);
	}

	_showAutolocationError(show) {
		this.$ctx.find('.js-auto-location-err').toggleClass('h-hidden', !show);
	}

	submitForm(ev) {
		ev.preventDefault();
		if (this.$form.valid()) {
			const zipCode = this.$plzInput.val();
			this.requestLocations(zipCode);
		}
	}
}
window.customElements.define('driving-safety-location-search', DrivingSafetyLocationSearch);