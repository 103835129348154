/// <reference path="../../../atoms/DrivingSafetyBase/js/drivingsafetybase.webc.js" />

/**
 * DrivingSafetyAppointmentLinkBase component to find appointments for one location.
 *
 * @author <marc.radziwill@merkle.com>
 * @class DrivingSafetyAppointmentLinkBase
*/
// eslint-disable-next-line no-unused-vars , no-undef
class DrivingSafetyAppointmentLinkBase extends DrivingSafetyBase {

	_locationId = "";
	_sessionTrainingName = "";

	_connectedCallback() {
		T.Utils.Application.init();
		this.$ctx = jQuery(this);
		this._readConfiguration();
		this._registerListeners();
		this._prepareComponent();
		this._render();
	}

	_registerListeners() { }

	_prepareComponent() { }

	_readConfiguration() {
		this.config = this.getAttr('data-config') ? JSON.parse(this.getAttr('data-config')) : {};
		this.advertisigKey = this.config.advertisingKey;
		this.drivingSafetyModel = this.getModelFromStore();
		this.appointmentLink = this.querySelector('.js-btn-appointment') ? this.querySelector('.js-btn-appointment') : undefined;
		this.couponLink = this.querySelector('.js-btn-coupon') ? this.querySelector('.js-btn-coupon') : undefined;
	}

	_render() { }
}

if (typeof module !== "undefined" && module.exports) {
	module.exports = DrivingSafetyAppointmentLinkBase;
}