/// <reference path="../../../atoms/DrivingSafetyResultListBase/js/drivingsafetyresultlistbase.webc.js" />

/**
 * DrivingSafetyTrainingList Search web component implementation.
 *
 * @author  <krystian.sadowski@mtc.berlin>, <marc.radziwill@merkle.com>
 * @class DrivingSafetyTrainingList
*/
// eslint-disable-next-line no-undef
class DrivingSafetyTrainingList extends DrivingSafetyResultListBase {

	btnLocation = '.js-btn-location';

	_connectedCallback() {
		T.Utils.Application.init();
		this.$ctx = jQuery(this);
		this._readConfiguration();
		this._addListeners();
		this._isConnected = true;
	}

	_disconnectedCallback() {
		if (this._isConnected) {
			this.$ctx.find('.js-search-option').off('change', this._searchChangeListener.bind(this));
		}
	}

	_readConfiguration() {
		super._readConfiguration();
		this.config = this.$ctx.data('config');
		this._apiGetTrainingTypes = this.config.api;
		this.isB2BComponent = this.config.b2b === ("True" || "true") ? true : false;
		this._marketingTag = this.config.marketingTag;
		this.innerList = this.querySelector('dsresult-component');
	}

	_requestData() {
		if (this.checkPreconditions()) {
			super._requestData();
		} else {
			this.hideList(true);
		}
	}

	hideList(hide) {
		this.$hideOnNoResultElems.each((idx, element) => {
			jQuery(element).toggleClass('h-hidden', hide);
		});
	}

	checkPreconditions() {
		return Object.keys(this._requestPayload).length > 0;
	}

	_handleEventDataSuccess(data) {
		if (data && data.length > 0) {
			this.hideList(false);
		} else {
			this.hideList(true);
		}
		super._handleEventDataSuccess(data);
	}

	_handleEventDataFailed() {

	}

	_clearWrapper() {
		this._$resultWrapper.empty();
	}

	_appendResultItemToView($resultItem, itemData) {
		if (itemData) {
			this._$resultWrapper.append($resultItem);
			T.Utils.Helper.initAdditionalModules(this.$ctx);
		}
	}

	_displayButtons($resultItem) {
		$resultItem.find(".js-btn-appointment").toggleClass("h-hidden", true);
		$resultItem.find(this.btnLocation).toggleClass("h-hidden", false);
		if (!this.isB2BComponent) {
			$resultItem.find(".js-btn-coupon").toggleClass("h-hidden", true);
		}
	}
}
window.customElements.define('driving-safety-training-list', DrivingSafetyTrainingList);