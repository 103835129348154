/// <reference path="../../../atoms/DrivingSafetyFilterBase/js/drivingsafetyfilterbase.webc.js" />

/**
 * DrivingSafetyClusterModule Filter component for cluster page.
 *
 * @author  <riccardo.jung@merkle.com>
 * @class DrivingSafetyClusterModule
*/

// eslint-disable-next-line no-undef
class DrivingSafetyClusterModule extends DrivingSafetyFilterBase {

	registerListeners() {
		super.registerListeners();
		this.$submitButton.on('click', this._submitForm.bind(this));
	}

	_disconnectedCallback() {
		if (this._isConnected) {
			this.$submitButton.off('click', this._submitForm.bind(this));
		}
	}
	_clearError() {
		this.$noBackend.toggleClass('h-hidden', true);
		this.$form.toggleClass('h-hidden', false);
	}

	_submitForm(ev) {
		ev.preventDefault();

		if (this.$form.valid()) {
			this.setFilterFormModelToStore();

			const filterType = this.$ctx.find("input[name='filter_type']:checked").parent().find('.js-category-name').text();
			const eventData = {
				eventCategory: 'Funnel',
				eventAction: 'Trainingssuche | funnel_click',
				eventLabel: 'Training suchen',
				product: 'Fahrsicherheit',
				productVariant: this.isB2BComponent ? 'B2B' : 'B2C',
				funnelStepInhalt: filterType
			};
			T.Utils.Tracking.pushData({
				eventData: eventData,
				event: 'click'
			});
			const url = this.updateUrlWithCampaignInfo(this.targetUrl);
			T.Utils.Helper.routeToUrl(url);
		}
	}
}
window.customElements.define('driving-safety-cluster-module', DrivingSafetyClusterModule);