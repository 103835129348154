/// <reference path="../../../atoms/DrivingSafetyBase/js/drivingsafetybase.webc.js" />

/**
 * DrivingSafetyAppointmentSearch implementation
 *
 * @author <krystian.sadowski@mtc.berlin>
 * @class DrivingSafetyAppointmentSearch
*/

// eslint-disable-next-line no-undef
class DrivingSafetyAppointmentSearch extends DrivingSafetyBase {
	tracking = false;

	_connectedCallback() {
		T.Utils.Application.init();
		this._readConfig();
		if (this.checkPreconditions()) {
			this._setTrainingData();
			this._registerListeners();
			this._initValidation();
			this._triggerViewStatus();
		} else if (!this.isExperienceEditor()) {
			const url = this.updateUrlWithCampaignInfo(this.notValidUrl);
			T.Utils.Helper.routeToUrl(url);
		}
	}

	_disconnectedCallback() {
		if (this._isConnected) {
			this.$filterButton.off('click', this._filterFormData.bind(this));
			this.$submitButton.off('click', this._submitAppointment.bind(this));
			this.$ctx.find(".js-table-row").off('click', this.clearTableErrorMessages.bind(this));
			if (this.$backButton && this.$backButton.length) {
				this.$backButton.on('click', this.backButtonListener.bind(this));
			}
		}
	}

	checkPreconditions() {
		const trainingModel = this.getTrainingModelFromStore();
		const locationModel = this.getLocationModelFromStore();
		return !!(trainingModel && trainingModel.Id && trainingModel.Name && locationModel.Id && locationModel.Name);
	}

	_readConfig() {
		this.$ctx = jQuery(this);
		this.config = this.$ctx.data('config');
		this.apiEvents = this.config.api;
		this.apiLocations = this.config.apiLocations;
		this.notValidUrl = this.config.noValidUrl;
		this.advertisigKey = this.config.advertisingKey;
		this.$form = this.$ctx.find('form.js-filter-form');
		this.$trainingTitleWrapper = this.$ctx.find('.js-training-title');
		this.$resultTableWrapper = this.$ctx.find('.js-result-table-wrapper');
		this.$resultWrapper = this.$ctx.find('.js-result-wrapper');
		this.$resultHint = this.$ctx.find('.js-result-hint');
		this.$submitButton = this.$ctx.find('.js-submit');
		this.$filterButton = this.$ctx.find('.js-filter');
		this.$infoText1 = this.$ctx.find('.js-info-text-1');
		this.$infoText2 = this.$ctx.find('.js-info-text-2');
		this.$infoText3 = this.$ctx.find('.js-info-text-3');
		this.$infoText1Wrapper = this.$ctx.find('.js-result-infotext-1-wrapper');
		this.$infoText2Wrapper = this.$ctx.find('.js-result-infotext-2-wrapper');
		this.$infoText3Wrapper = this.$ctx.find('.js-result-infotext-3-wrapper');
		this.$alternativeResultWrapper = this.$ctx.find('.js-alternative-result-wrapper');
		this.$filterLocation = this.$ctx.find('.js-filter-location');
		this.$filterStartDate = this.$ctx.find('.js-filter-start-date');
		this.$filterEndDate = this.$ctx.find('.js-filter-end-date');
		this.$filterNumParticipant = this.$ctx.find('.js-filter-numParticipant');
		this.$stickyWrapper = this.$ctx.find('.js-sticky');
		this.$noResult = this.$ctx.find('.js-no-results');
		this.$btnCoupon = this.$ctx.find('.js-btn-coupon');
		this.$couponWrapper = this.$ctx.find('.js-coupon-wrapper');
		this.$backButton = this.$ctx.find('.js-back-btn');

		this.$dsResultComponentTemplate = jQuery(this.$ctx.find(".js-filter-result-component-template").html());
		this.$resultTemplate = jQuery(this.$ctx.find(".js-result-template").html());
		this._showHint1 = false;
		this._showHint2 = false;
		this._showHint3 = false;
		this._changeTimePeriod = false;
		this._showAlternativeResult = false;
	}

	_triggerViewStatus() {
		const locationModel = this.getLocationModelFromStore();

		if (locationModel.Id) {
			this.tracking = false;
			this.$filterButton.trigger('click');
		}
	}

	_setTrainingData() {
		const drivingSafetyModel = this.getModelFromStore();
		if (T.Utils.Helper.isNullUndefinedOrEmpty(drivingSafetyModel, 'filter')) {
			if (drivingSafetyModel.filter.Participants) {
				this.$filterNumParticipant.val(drivingSafetyModel.filter.Participants);
			}
			if (drivingSafetyModel.filter.Start) {
				this.$filterStartDate.val(drivingSafetyModel.filter.Start);
			}
			if (drivingSafetyModel.filter.End) {
				this.$filterEndDate.val(drivingSafetyModel.filter.End);
			}
		}
		if (T.Utils.Helper.isNullUndefinedOrEmpty(drivingSafetyModel, 'location') && T.Utils.Helper.isNullUndefinedOrEmpty(drivingSafetyModel, 'location.Id')) {
			const allLocations = T.Utils.Helper.isNullUndefinedOrEmpty(drivingSafetyModel, 'location.sublocations') ? drivingSafetyModel.location.sublocations : [{ id: drivingSafetyModel.location.Id, name: drivingSafetyModel.location.Name }];
			this._createFilterLocationEntry(allLocations, drivingSafetyModel.location.Id);
		}
		this.$trainingTitleWrapper.text(drivingSafetyModel.training.Name);
	}

	_createFilterLocationEntry(locations, currentLocationId) {
		locations.forEach(location => {
			const $locationEntry = jQuery(`<option value="${location.id}">${location.name}</option>`);

			if (currentLocationId === location.id) {
				$locationEntry.prop("selected", true);
			}
			this.$filterLocation.append($locationEntry);
		});
	}

	_registerListeners() {
		this.$filterButton.on('click', this._filterFormData.bind(this));
		this.$submitButton.on('click', this._submitAppointment.bind(this));
		this.$backButton.on('click', this.backButtonListener.bind(this));
	}

	_submitAppointment(e) {
		e.preventDefault();

		const $appointments = this.$ctx.find('[name="appointments"]');
		T.Utils.Validation.addValidationForElement($appointments, {
			required: true,
			messages: this.validationOptions.messages.appointments
		});

		if (this.$form.valid()) {
			this._trackAppointmentSubmit();

			const $activeRow = this.$ctx.find('.js-ds-result-component').find('.js-table-row.is-active');
			let bookingUrl = $activeRow.data('booking-url');
			if (T.Utils.Helper.isNullUndefinedOrEmpty(this.advertisigKey)) {
				bookingUrl = T.Utils.Helper.updateUrlParameter({ advertisingKey: this.advertisigKey }, bookingUrl);
			}
			T.Utils.Helper.routeToUrl(bookingUrl);
		}
	}

	_trackAppointmentSubmit() {
		const eventData = {
			eventCategory: 'Funnel',
			eventAction: 'Terminauswahl | funnel_click',
			eventLabel: 'Termin buchen'
		};

		T.Utils.Tracking.pushData({
			eventData: eventData,
			event: 'click'
		});
	}

	_initValidation() {
		this.validationOptions = {
			messages: this.$form.data('validation-messages') ? JSON.parse(this.$form.data('validation-messages')) : {},
			rules: {
				filter_location: {
					requiredsel: true
				}
			}
		};
		T.Utils.Validation.init(this.$ctx, this.$form, this.validationOptions);

		jQuery.validator.addMethod('requiredsel', function (value) {
			return ("-1" !== value);
		});
	}

	_filterFormData(ev) {
		ev.preventDefault();
		const $appointments = this.$ctx.find('[name="appointments"]');
		T.Utils.Validation.removeValidationForElement($appointments);

		if (this.$form.valid()) {
			T.Utils.View.startLoader();
			const locationModel = this.getLocationModelFromStore();
			if (locationModel.Id !== parseInt(this.$filterLocation.find(":selected").val()) || !T.Utils.Helper.isNullUndefinedOrEmpty(locationModel, 'CouponLink')) {
				this._requestLocations();
			}
			this._handleCouponButtonVisibility();
			this._setFilterModelToStore();
			if (this.tracking) {
				this._trackAppointmentFilter();
			}
			this._clearResults();
			this._requestEvents();

			if (!this.tracking) {
				this.tracking = true;
			}
		}
	}

	_trackAppointmentFilter() {
		const eventData = {
			eventCategory: 'Funnel',
			eventAction: 'Terminauswahl | filter_click',
			eventLabel: 'Filter anwenden'
		};
		T.Utils.Tracking.pushData({
			eventData: eventData,
			event: 'click'
		});
	}

	_setFilterModelToStore() {
		const drivingSafetyModel = T.Utils.Store.get(this.SESSION_KEY, T.Utils.Store.SESSION) || { filter: {}, location: {} };

		drivingSafetyModel.filter.Start = this.$filterStartDate.val();
		drivingSafetyModel.filter.End = this.$filterEndDate.val();
		drivingSafetyModel.filter.Participants = this.$filterNumParticipant.find(":selected").val();
		drivingSafetyModel.location.Id = parseInt(this.$filterLocation.find(":selected").val());
		drivingSafetyModel.location.Name = this.$filterLocation.find(":selected").text();
		T.Utils.Store.set(this.SESSION_KEY, drivingSafetyModel, T.Utils.Store.SESSION);
	}

	_clearResults() {
		this.$stickyWrapper.toggleClass('h-hidden', true);
		this.$resultTableWrapper.toggleClass('h-hidden', true);
		this.$resultHint.toggleClass('h-hidden', true);
		this.$infoText1Wrapper.toggleClass('h-hidden', true);
		this.$infoText1.text("");
		this.$infoText2Wrapper.toggleClass('h-hidden', true);
		this.$infoText2.text("");
		this.$infoText3Wrapper.toggleClass('h-hidden', true);
		this.$infoText3.text("");
		this.$alternativeResultWrapper.toggleClass('h-hidden', true);
		this._showHint1 = false;
		this._showHint2 = false;
		this._showHint3 = false;
		this._changeTimePeriod = false;
		this._showAlternativeResult = false;
		this.$ctx.find('.js-ds-result-component').remove();
	}

	_requestEvents(changeTimePeriod) {
		const searchData = this._getSearchData(changeTimePeriod);
		const request = {
			method: 'post',
			url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this.apiEvents),
			data: JSON.stringify(searchData),
			headers: { "Ocp-Apim-Subscription-Key": T.Utils.Application.getApiMSubscriptionKey() }
		};
		T.Utils.Ajax.fragment(request, this._handleRequestEventsSuccess.bind(this), this._handleRequestEventDataFailed.bind(this));
	}

	_requestLocations() {
		const trainingModel = this.getTrainingModelFromStore();

		const payload = {
			locationName: this.$filterLocation.find(":selected").text(),
			trainingID: trainingModel.Id
		};

		const request = {
			method: 'post',
			url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this.apiLocations),
			data: JSON.stringify(payload),
			headers: { "Ocp-Apim-Subscription-Key": T.Utils.Application.getApiMSubscriptionKey() }
		};
		T.Utils.Ajax.fragment(request, this._handleRequestLocationsSuccess.bind(this), this._handleRequestLocationsFailed.bind(this));
	}

	_handleRequestLocationsSuccess(data) {
		if (data && data.length > 0 && T.Utils.Helper.isNullUndefinedOrEmpty(data[0], 'bookingUrl.coupon')) {
			const locationModel = this.getLocationModelFromStore();
			locationModel.CouponLink = data[0].bookingUrl.coupon;
			this._setLocationModelToStore(locationModel);
		}
		else {
			this.$couponWrapper.toggleClass('h-hidden', true);
		}
		this._handleCouponButtonVisibility();
	}

	_handleCouponButtonVisibility() {
		const locationModel = this.getLocationModelFromStore();
		if (!T.Utils.Helper.isEmptyString(locationModel.CouponLink)) {
			let couponLink = locationModel.CouponLink;
			if (T.Utils.Helper.isNullUndefinedOrEmpty(this.advertisigKey)) {
				couponLink = T.Utils.Helper.updateUrlParameter({ advertisingKey: this.advertisigKey }, couponLink);
			}
			this.$btnCoupon.attr('href', couponLink);
			this.$btnCoupon.on('click', () => {
				const eventData = {
					eventCategory: 'Funnel',
					eventAction: 'Terminauswahl | button_click',
					eventLabel: 'Gutschein kaufen'
				};

				T.Utils.Tracking.pushData({
					eventData: eventData,
					event: 'click'
				});
			});
			this.$couponWrapper.toggleClass('h-hidden', false);
		}
		else {
			this.$couponWrapper.toggleClass('h-hidden', true);
		}
	}

	_handleRequestLocationsFailed() {
		this.$couponWrapper.toggleClass('h-hidden', true);
	}

	_getSearchData(changeTimePeriod) {
		const trainingModel = this.getTrainingModelFromStore();
		const locationModel = this.getLocationModelFromStore();
		const numParticipant = parseInt(this.$filterNumParticipant.find(":selected").val());
		const startDate = this.$filterStartDate.val();
		const endDate = this.$filterEndDate.val();

		const searchData = {
			trainingID: trainingModel.Id,
			locationName: locationModel.Name,
			numParticipant: numParticipant
		};

		if (changeTimePeriod) {
			const periodTo = !T.Utils.Helper.isEmptyString(endDate) ? new Date(T.Utils.Format.viewDateToIsoDate(endDate)) : "";
			if (!T.Utils.Helper.isEmptyString(periodTo)) {
				searchData.periodTo = periodTo.toISOString(periodTo.setMonth(periodTo.getMonth() + 3));
			}
		}
		else {
			searchData.periodTo = !T.Utils.Helper.isEmptyString(endDate) ? T.Utils.Format.viewDateToIsoDate(endDate) : "";
		}

		searchData.periodFrom = !T.Utils.Helper.isEmptyString(startDate) ? T.Utils.Format.viewDateToIsoDate(startDate) : "";
		return searchData;
	}

	_handleRequestEventsSuccess(data) {
		if (data && data.length > 0) {
			this.hideNoResultElem(true);

			data = data.sort((a, b) => new Date(b.training.date) - new Date(a.training.date)).reverse();
			const $dsResultComponent = this.$dsResultComponentTemplate.clone();

			for (let i = 0; i < data.length; i++) {
				const itemData = data[i];
				if (itemData) {
					this._fillDsResultComponentData($dsResultComponent, itemData, i);
					this._setInfoTextData(itemData);
					this._evaluateResultHint(itemData);
					this._evaluateAlternativeResult(itemData, $dsResultComponent);
				}
			}

			this.$resultTableWrapper.toggleClass('h-hidden', false);
			this.$stickyWrapper.toggleClass('h-hidden', false);

			this.$resultTableWrapper.append($dsResultComponent);

			this._evaluateHintsVisibility();

			this._evaluateAlternativeResultHintVisibility();

			this.$ctx.find(".js-table-row").on('click', this.clearTableErrorMessages.bind(this));
		}
		else {
			if (!this._changeTimePeriod) {
				this._changeTimePeriod = true;
				this._showAlternativeResult = true;
				this._requestEvents(true);

			}
			else {
				this.hideNoResultElem(false);
			}

		}

		T.Utils.Helper.removeModules(this.$stickyWrapper);
		T.Utils.Helper.initAdditionalModules(this.$stickyWrapper);

		T.Utils.View.stopLoader();
	}

	hideNoResultElem(hide) {
		this.$noResult.toggleClass('h-hidden', hide);
	}

	clearTableErrorMessages() {
		this.$resultTableWrapper.find('.js-row').removeClass('is-error');
		this.$resultTableWrapper.find('.ll-error-msg').text('');
	}

	_evaluateHintsVisibility() {
		if (this._showHint1) {
			this.$infoText1Wrapper.toggleClass('h-hidden', false);
		}
		if (this._showHint2) {
			this.$infoText2Wrapper.toggleClass('h-hidden', false);
		}
		if (this._showHint3) {
			this.$infoText3Wrapper.toggleClass('h-hidden', false);
		}
	}

	_evaluateAlternativeResultHintVisibility() {
		this.$ctx.find('.js-alternative-result-period').toggleClass('h-hidden', !this._changeTimePeriod);
		this.$alternativeResultWrapper.toggleClass('h-hidden', !this._showAlternativeResult);
	}


	_fillDsResultComponentData($dsResultComponent, itemData, i) {
		const $eventData = this.$resultTemplate.clone();
		const identifier = `appointments_${i}`;
		$eventData.find('.js-result-date-input').attr("id", identifier);
		$eventData.find('label').attr("for", identifier);
		$eventData.find('.js-result-date').text(itemData.training.dateFormatted);
		$eventData.find('.js-result-start-time').text(itemData.training.startTime.substring(0, 5));
		$eventData.find('.js-result-end-time').text(itemData.training.endTime.substring(0, 5));
		$eventData.find('.js-result-location').text(itemData.location.name);

		$eventData.find('.js-alternative-result-row').toggleClass('h-hidden', !itemData.training.isAlternativeResult);

		if (itemData.training.priceMember === itemData.training.priceNonMember) {
			$eventData.find('.js-result-price-nonmember').text(`${itemData.training.priceMember} €`);
		}
		else {
			$eventData.find('.js-result-price-member').text(`${itemData.training.priceMember} €`);
			$eventData.find('.js-result-price-nonmember').text(`${itemData.training.priceNonMember} €`);
			$eventData.find('.js-result-price-member-wrapper').toggleClass('h-hidden', false);
		}
		$eventData.find('.js-result-free-places').text(itemData.training.sumAvailable);
		itemData.training.sumAvailable === 1 ? $eventData.find('.js-result-free-place-one').toggleClass('h-hidden', false) : $eventData.find('.js-result-free-place-multiple').toggleClass('h-hidden', false);

		T.Utils.Helper.updateUrlParameter(
			{ numParticipant: this.$filterNumParticipant.find(":selected").val() },
			itemData.bookingUrl.training);

		T.Utils.View.setDataSafe($eventData, 'booking-url', itemData.bookingUrl.training);

		$dsResultComponent.find('.js-result-wrapper').append($eventData);
	}

	_evaluateResultHint(itemData) {
		if (itemData.training.orderNoteTraining.title !== null) {
			this.$resultHint.find('.js-result-hint-title').text(itemData.training.orderNoteTraining.title);
			this.$resultHint.toggleClass('h-hidden', false);
		}
		if (itemData.training.orderNoteTraining.body !== null) {
			this.$resultHint.find('.js-result-hint-text').text(itemData.training.orderNoteTraining.body);
			this.$resultHint.toggleClass('h-hidden', false);
		}
	}

	_evaluateAlternativeResult(itemData, dsResultComponent) {
		const isAlternativeResult = itemData.training.isAlternativeResult;
		if (isAlternativeResult) {
			this._showAlternativeResult = true;
		}
		this.$ctx.find('.js-alternative-result-text').toggleClass('h-hidden', !isAlternativeResult);
		dsResultComponent.find('.js-alternative-result-headline').toggleClass('h-hidden', !isAlternativeResult);
	}

	_setInfoTextData(itemData) {
		if (T.Utils.Helper.isEmptyString(this.$infoText1.text()) && !T.Utils.Helper.isEmptyString(itemData.orderTxt.txt1)) {
			this.$infoText1.text(itemData.orderTxt.txt1);
			this._showHint1 = true;
		}

		if (T.Utils.Helper.isEmptyString(this.$infoText2.text()) && !T.Utils.Helper.isEmptyString(itemData.orderTxt.txt2)) {
			this.$infoText2.text(itemData.orderTxt.txt2);
			this._showHint2 = true;
		}

		if (T.Utils.Helper.isEmptyString(this.$infoText3.text()) && !T.Utils.Helper.isEmptyString(itemData.orderTxt.txt3)) {
			this.$infoText3.text(itemData.orderTxt.txt3);
			this._showHint3 = true;
		}
	}

	_handleRequestEventDataFailed() {
		T.Utils.View.stopLoader();
		T.Utils.View.showGeneralErrorLayer();
	}

	_setLocationModelToStore(locationModel) {
		const drivingSafetyModel = this.getModelFromStore();
		drivingSafetyModel.location = locationModel;
		T.Utils.Store.set(this.SESSION_KEY, drivingSafetyModel, T.Utils.Store.SESSION);
	}
}
window.customElements.define('driving-safety-appointment-search', DrivingSafetyAppointmentSearch);