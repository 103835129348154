/// <reference path="../../../atoms/DrivingSafetyAppointmentLinkBase/js/drivingsafetyappointmentlinkbase.webc.js" />

/**
 * DrivingSafetyLocationTrainingsList List of all Trainings for one Location.
 *
 * @author  <timo.mueller@mtc.berlin>, <marc.radziwill@merkle.com>
 * @class DrivingSafetyLocationTrainingsList
*/

// eslint-disable-next-line no-undef
class DrivingSafetyAppointmentLink extends DrivingSafetyAppointmentLinkBase {

	_displayComponent = false;
	_locationId = "";
	_sessionTrainingName = "";

	_prepareComponent() {
		if (this.checkPreconditions()) {
			this._displayComponent = true;
		}
	}

	checkPreconditions() {
		const trainingsModel = this.drivingSafetyModel ? this.drivingSafetyModel.training : undefined;
		const locationModel = this.drivingSafetyModel ? this.drivingSafetyModel.location : undefined;

		if (T.Utils.Helper.isNullUndefinedOrEmpty(trainingsModel) && T.Utils.Helper.isNullUndefinedOrEmpty(locationModel)) {
			const sessionTrainingId = trainingsModel.Id;
			this._sessionTrainingName = trainingsModel.Name;
			const sessionLocationId = parseInt(locationModel.Id);
			if (this.dataset.locationid !== undefined) {
				this._locationId = parseInt(this.dataset.locationid);
			}
			if (this._locationId !== "" && sessionTrainingId !== "" && T.Utils.Helper.isNullUndefinedOrEmpty(sessionTrainingId) && T.Utils.Helper.isNullUndefinedOrEmpty(sessionLocationId) && this._locationId === sessionLocationId) {
				return true;
			}
		}
		return false;
	}

	_render() {
		if (this._displayComponent) {
			this._$headline = this.$ctx.find('.js-headline');
			this._$text = this.$ctx.find('.js-text');
			this._$headline.html(this._$headline.html().replace('{TRAININGNAME}', this._sessionTrainingName));
			this._$text.html(this._$text.html().replace('{TRAININGNAME}', this._sessionTrainingName));
			if (this.appointmentLink) {
				const url = this.updateUrlWithCampaignInfo(this.config.appointmentUrl ? this.config.appointmentUrl : window.location.href);
				this.appointmentLink.setAttribute('href', url);
			}

			if (this.couponLink && T.Utils.Helper.isNullUndefinedOrEmpty(this.drivingSafetyModel.location.CouponLink)) {
				let link = this.drivingSafetyModel.location.CouponLink;
				if (T.Utils.Helper.isNullUndefinedOrEmpty(this.advertisigKey)) {
					link = T.Utils.Helper.updateUrlParameter({ advertisingKey: this.advertisigKey }, link);
				}
				this.couponLink.setAttribute('href', link);
			} else {
				this.couponLink.classList.add('h-hidden');
			}
		}

		this.$ctx.toggleClass("h-hidden", !this._displayComponent);
	}

}
window.customElements.define('driving-safety-appointment-link', DrivingSafetyAppointmentLink);