/// <reference path="../../../atoms/DrivingSafetyResultListBase/js/drivingsafetyresultlistbase.webc.js" />

/**
 * DrivingSafetyTrainingsAtLocationList List of all Trainings for one Location.
 *
 * @author  <timo.mueller@mtc.berlin>, <marc.radziwill@merkle.com>
 * @class DrivingSafetyTrainingsAtLocationList
*/

// eslint-disable-next-line no-undef
class DrivingSafetyTrainingsAtLocationList extends DrivingSafetyResultListBase {

	_connectedCallback() {
		super._connectedCallback();
	}

	_readConfiguration() {
		super._readConfiguration();
		this.config = this.$ctx.data('config');
		this._apiGetTrainingTypes = this.config.api;
		this._apiGetLocation = this.config.apiGetLocation;
		this.isB2BComponent = this.config.b2b === ("True" || "true") ? true : false;
		this.advertisigKey = this.config.advertisingKey;
		this._getFilterData();
		this._$result_car = this.$ctx.find(".js-result-car");
		this._$resultWrapper_car = this.$ctx.find('.js-result-wrapper-car');
		this._$result_motorcycle = this.$ctx.find(".js-result-motorcycle");
		this._$resultWrapper_motorcycle = this.$ctx.find('.js-result-wrapper-motorcycle');
		this._$result_motorhome = this.$ctx.find(".js-result-motorhome");
		this._$resultWrapper_motorhome = this.$ctx.find('.js-result-wrapper-motorhome');
		this._$result_utility = this.$ctx.find(".js-result-utility");
		this._$resultWrapper_utility = this.$ctx.find('.js-result-wrapper-utility');
		this._$result_offroad = this.$ctx.find(".js-result-offroad");
		this._$resultWrapper_offroad = this.$ctx.find('.js-result-wrapper-offroad');
		this._$result_drift = this.$ctx.find(".js-result-drift");
		this._$resultWrapper_drift = this.$ctx.find('.js-result-wrapper-drift');
	}

	_clearWrapper() {
		this._$resultWrapper_car.empty();
		this._$resultWrapper_motorcycle.empty();
		this._$resultWrapper_motorhome.empty();
		this._$resultWrapper_utility.empty();
		this._$resultWrapper_offroad.empty();
		this._$resultWrapper_drift.empty();
	}

	_appendResultItemToView($resultItem, itemData) {
		if (T.Utils.Helper.isNullUndefinedOrEmpty(itemData, 'training.category') && itemData.training.category.uid) {
			switch (itemData.training.category.uid) {
				case 1:
					this._$resultWrapper_car.append($resultItem);
					this._$result_car.toggleClass("h-hidden", false);
					this._$resultWrapper_car.toggleClass("h-hidden", false);
					break;
				case 2:
					this._$result_motorcycle.toggleClass("h-hidden", false);
					this._$resultWrapper_motorcycle.toggleClass("h-hidden", false);
					this._$resultWrapper_motorcycle.append($resultItem);
					break;
				case 3:
					this._$result_utility.toggleClass("h-hidden", false);
					this._$resultWrapper_utility.toggleClass("h-hidden", false);
					this._$resultWrapper_utility.append($resultItem);
					break;
				case 4:
					this._$result_motorhome.toggleClass("h-hidden", false);
					this._$resultWrapper_motorhome.toggleClass("h-hidden", false);
					this._$resultWrapper_motorhome.append($resultItem);
					break;
				case 5:
					this._$result_offroad.toggleClass("h-hidden", false);
					this._$resultWrapper_offroad.toggleClass("h-hidden", false);
					this._$resultWrapper_offroad.append($resultItem);
					break;
				case 6:
					this._$result_drift.toggleClass("h-hidden", false);
					this._$resultWrapper_drift.toggleClass("h-hidden", false);
					this._$resultWrapper_drift.append($resultItem);
					break;
				default:
					break;
			}

			T.Utils.Helper.initAdditionalModules(this.$ctx);
		}
	}

	_displayButtons($resultItem) {
		$resultItem.find(".js-btn-appointment").toggleClass("h-hidden", false);
		$resultItem.find(".js-btn-location").toggleClass("h-hidden", true);
		if (!this.isB2BComponent) {
			$resultItem.find(".js-btn-coupon").toggleClass("h-hidden", false);
		}
	}

	_registerButtonListeners($resultItem, itemData) {
		if ($resultItem.find(this.btnLocation)) {
			$resultItem.find(this.btnLocation).on('click', this.handleLocationSearchClick.bind(this, itemData));
		}
		if ($resultItem.find(this.btnAppointment)) {
			$resultItem.find(this.btnAppointment).on('click', this.handleAppointmentSearchClick.bind(this, itemData));
		}

		if (!this.isB2BComponent) {

			if (T.Utils.Helper.isNullUndefinedOrEmpty(itemData, this.bookigUrlName)) {
				const $couponLink = $resultItem.find(this.couponSelector);
				const eventData = {
					eventCategory: 'Funnel',
					eventAction: 'Standortdetailseite | button_click',
					eventLabel: 'Gutschein kaufen',
					// eventValue: `${itemData.training.name} | ${itemData.location.name}` //NOSONAR //will be implemented soon
				};
				T.Utils.Tracking.updateEventClickData($couponLink, eventData);
				let link = itemData.bookingUrl.coupon;
				if (T.Utils.Helper.isNullUndefinedOrEmpty(this.advertisigKey)) {
					link = T.Utils.Helper.updateUrlParameter({ advertisingKey: this.advertisigKey }, link);
				}
				$couponLink.attr('href', link);
			} else {
				$resultItem.find(this.couponSelector).addClass('h-hidden');
			}
		}
	}

	handleAppointmentSearchClick(itemData, e) {
		e.preventDefault();

		this.trackAppointmentEventClick();

		if (!this.isB2BComponent) {
			if (T.Utils.Helper.isNullUndefinedOrEmpty(itemData, 'training')) {
				this.setTrainingModelToStore({
					Name: itemData.training.name,
					Id: itemData.training.id,
					Link: itemData.training.detailLink,
					Desc: itemData.training.headline
				});
			}

			if (T.Utils.Helper.isNullUndefinedOrEmpty(itemData, 'location')) {
				const locationObj = {
					Id: itemData.location.locationID,
					Name: itemData.location.name,
					Link: itemData.location.detailLink
				};

				if (T.Utils.Helper.isNullUndefinedOrEmpty(itemData, 'bookingUrl.coupon')) {
					locationObj.CouponLink = itemData.bookingUrl.coupon;
				}
				this.setLocationModelToStore(locationObj);
			}
			this.setMode('appointment');
			const url = this.updateUrlWithCampaignInfo(this.config.appointmentUrl);
			T.Utils.Helper.routeToUrl(url);
		} else {
			const url = itemData.bookingUrl.training;
			T.Utils.Helper.routeToUrl(url);
		}

	}

	trackAppointmentEventClick() {
		const eventData = {
			eventCategory: 'Funnel',
			eventAction: 'Standortdetailseite | funnel_click',
			eventLabel: 'Termin finden Liste',
		};

		T.Utils.Tracking.pushData({
			eventData: eventData,
			event: 'click'
		});
	}
}
window.customElements.define('driving-safety-trainings-at-location-list', DrivingSafetyTrainingsAtLocationList);