/// <reference path="../../../atoms/DrivingSafetyFilterBase/js/drivingsafetyfilterbase.webc.js" />

/**
 * DrivingSafetyFilter Filter component for cluster page.
 *
 * @author  <marc.radziwill@merkle.com>
 * @class DrivingSafetyFilter
*/

// eslint-disable-next-line no-undef
class DrivingSafetyFilter extends DrivingSafetyFilterBase {

	listComponent = 'driving-safety-location-list';

	filterButton = '.js-filter-btn';

	constructor() {
		super();
		this.filtermode = 'full';
	}

	_connectedCallback() {
		super._connectedCallback();
		if (!this.isB2BComponent) {
			this.requestCars();
			this.requestDuration();
		}
	}

	_disconnectedCallback() {
		if (this._isConnected) {
			this.off('dofiler', this.applyFilter.bind(this));
			this.deleteFilter.removeEventListener('click', this.clearFilter.bind(this));
			this.querySelector(this.listComponent).off('filterreset', this.clearFilter.bind(this));
			this.querySelector(this.filterButton).removeEventListener('click', this.applyFilter.bind(this));
		}
	}

	readConfig() {
		super.readConfig();
		this.mode = this.getMode();
		this.lists = this.querySelectorAll('driving-safety-training-list,driving-safety-location-list');
		this.deleteFilter = this.querySelector('.js-delete-filter');
		this.$carTemplate = jQuery(this.$ctx.find(".js-car-template").html());
		this.$durationTemplate = jQuery(this.$ctx.find(".js-duration-template").html());
		this.$vehicleTemplate = jQuery(this.$ctx.find(".js-vehicle-template").html());
		this.$durations = this.$ctx.find('.js-duration');
		this.$vehicles = this.$ctx.find('.js-vehicles');
		if (this.isB2BComponent) {
			this.apiRequests = 1;
		} else {
			this.apiRequests = this.requestedExperiences ? 4 : 3;
		}
		this.apiCar = this.config.apiTrainingCar;
		this.apiDuration = this.config.apiTrainingDuration;
		this.deleteFilter.addEventListener('click', this.clearFilter.bind(this));
	}


	requestCars() {
		const request = {
			method: 'get',
			url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this.apiCar),
			headers: { "Ocp-Apim-Subscription-Key": T.Utils.Application.getApiMSubscriptionKey() }
		};
		T.Utils.Ajax.fragment(request, this.handleRequestCarSuccess.bind(this), this.handleRequestFailed.bind(this));
	}

	requestDuration() {
		const request = {
			method: 'get',
			url: T.Utils.Helper.appendURIPath(T.Utils.Application.getApiM(), this.apiDuration),
			headers: { "Ocp-Apim-Subscription-Key": T.Utils.Application.getApiMSubscriptionKey() }
		};
		T.Utils.Ajax.fragment(request, this.handleRequestDurationSuccess.bind(this), this.handleRequestFailed.bind(this));
	}

	handleRequestDurationSuccess(data) {
		if (data && data.length > 0) {
			for (let i = 0; i < data.length; i++) {
				const itemData = data[i];
				if (itemData) {
					this._createDurationAndAppendToView(itemData);
				}
			}
		}
		this.emitEvent('dataready');
		T.Utils.View.stopLoader();
	}

	handleRequestCarSuccess(data) {
		if (data && data.length > 0) {
			for (let i = 0; i < data.length; i++) {
				const itemData = data[i];
				if (itemData) {
					this._createCarAndAppendToView(itemData);
				}
			}
		}
		this.emitEvent('dataready');
		T.Utils.View.stopLoader();
	}

	_createCarAndAppendToView(itemData) {
		const $vehicle = this.$vehicleTemplate.clone();
		$vehicle.find('.js-vehicle-name').text(itemData.value);
		const id = "add_vehicle_";
		const uid = itemData.uid;
		const idValue = `${id}${uid}`;
		$vehicle.find('input').prop('id', idValue).prop('value', uid);
		$vehicle.find('label').prop('for', idValue);

		this.$vehicles.append($vehicle);
	}

	_createDurationAndAppendToView(itemData) {
		const $duration = this.$durationTemplate.clone();
		$duration.find('.js-duration-name').text(itemData.value);
		const id = "add_duration_";
		const uid = itemData.uid;
		const idValue = `${id}${uid}`;
		$duration.find('input').prop('id', idValue).prop('value', uid);
		$duration.find('label').prop('for', idValue);
		this.$durations.append($duration);
	}

	registerListeners() {
		super.registerListeners();
		this.querySelector(this.filterButton).addEventListener('click', this.applyFilter.bind(this));
		this.on('dofiler', this.applyFilter.bind(this));
		if (this.querySelector(this.listComponent)) {
			this.querySelector(this.listComponent).on('filterreset', this.clearFilter.bind(this));
		}
	}

	activateFilter() {
		super.activateFilter();
		if (this.isMode('training')) {
			this.handleVehicleType();
		}
		if (Object.keys(this.getFilterModelFromStore()).length) {
			this.deleteFilter.style.display = 'block';
		}
	}

	handleVehicleType() {
		if (!this.isB2BComponent) {
			const currentType = this.$ctx.find('input[name="filter_type"]:checked');
			const value = currentType.val();

			const vehiclesSection = this.querySelector('.js-vehicles-section');
			const durationSection = this.querySelector('.js-duration-section');
			const $additionalContent = this.$ctx.find('.js-additional-content,.js-additional-toggle');
			durationSection.classList.remove('mm-additional-item--full');
			$additionalContent.toggleClass('h-hidden', false);
			if (currentType) {
				switch (value) {
					case '1':
						vehiclesSection.classList.add('h-hidden');
						durationSection.classList.add('mm-additional-item--full');
						break;
					case '2':
						vehiclesSection.classList.remove('h-hidden');
						break;
					case '3':
					case '4':
					case '5':
					case '6':
						$additionalContent.toggleClass('h-hidden', true);
						this.removeAdditionalContentFromStore();
						break;
				}
			}
		}
	}

	removeAdditionalContentFromStore() {
		const filter = this.getFilterModelFromStore();

		delete filter.Vehicle;
		delete filter.Duration;
		delete filter.Grand;
		delete filter.TrainingExpertise;

		this.setFilterModelToStore(filter);
	}

	applyFilter(e) {
		e.preventDefault();
		if (this.$form.valid()) {
			this.$ctx.find('.js-close').trigger('click');
			this.setFilterFormModelToStore();
			const filter = e.detail;
			if (this.isMode('location')) {
				this.deleteFilter.style.display = 'block';
				if (!filter.firstClick) {
					this.trackLocationFilterClick();
				}
			} else {
				if (!filter.firstClick) {
					this.trackTrainingFilterClick();
				}
			}

			this.informChildren();
		}
	}

	trackLocationFilterClick() {
		const eventData = {
			eventCategory: 'Funnel',
			eventAction: 'Standortauswahl | filter_click',
			eventLabel: 'Filter anwenden'
		};

		T.Utils.Tracking.pushData({
			eventData: eventData,
			event: 'click'
		});
	}

	getRadiusFromSelection(radius) {
		return radius === 'smaller100' ? 100 : 1000;
	}

	trackTrainingFilterClick() {
		const eventData = {
			eventCategory: 'Funnel',
			eventAction: 'Trainingsauswahl | filter_click',
			eventLabel: 'Filter anwenden'
		};

		T.Utils.Tracking.pushData({
			eventData: eventData,
			event: 'click'
		});
	}

	informChildren() {
		if (this.lists && this.lists.length) {
			this.lists.forEach((list) => {
				list.setAttr('data-filter', JSON.stringify(this.toRequestObject()));
			});
		}
	}

	trackFilterReset() {
		if (this.isMode('location')) {
			T.Utils.Tracking.pushData({
				eventData: {
					eventCategory: 'Funnel',
					eventAction: 'Standortauswahl | interaction_click',
					eventLabel: 'Filter zurücksetzen'
				},
				event: 'click'
			});
		}
	}
	clearFilter() {
		this.trackFilterReset();
		this.clearModelData();
		this.filterComponent.deleteAllFilter();
		if (this.lists && this.lists.length) {
			this.lists.forEach((list) => {
				if (list.clearList) {
					list.clearList();
				}
				if (list.hideList) {
					list.hideList(true);
				}
				list.setAttr('data-filter', JSON.stringify({}));
			});
		}
		this.$plzInput.val('');
	}

	toRequestObject() {
		const inputModel = T.Utils.Store.get(this.SESSION_KEY, T.Utils.Store.SESSION);
		const request = {};

		if (inputModel.filter) {
			if (inputModel.filter.VehicleType) {
				request.categoryID = inputModel.filter.VehicleType;
			}
			if (inputModel.filter.TrainingExpertise) {
				request.trainingExperienceID = inputModel.filter.TrainingExpertise;
			}
			if (inputModel.filter.Participants) {
				request.numParticipant = inputModel.filter.Participants;
			}
			if (inputModel.filter.Radius) {
				if (inputModel.filter.Radius === 'smaller100') {
					request.radius = 100;
				} else if (inputModel.filter.Radius === 'greater100') {
					request.radius = 1000;
				}
			}
			if (inputModel.filter.Start) {
				request.periodFrom = T.Utils.Format.viewDateToIsoDate(inputModel.filter.Start);
			}
			if (inputModel.filter.End) {
				request.periodTo = T.Utils.Format.viewDateToIsoDate(inputModel.filter.End);
			}
			if (inputModel.filter.Duration) {
				request.trainingDurationID = inputModel.filter.Duration;
			}
			if (inputModel.filter.Vehicle) {
				request.trainingCarID = inputModel.filter.Vehicle;
			}
			if (inputModel.filter.Grand) {
				request.hasBgGrant = inputModel.filter.Grand === 'on' ? 1 : 0;
			}
			if (inputModel.filter.Postcode) {
				request.zip = inputModel.filter.Postcode;
			}
		}
		return request;
	}

	_clearError() {
		this.$noBackend.toggleClass('h-hidden', true);
		this.$form.toggleClass('h-hidden', false);
	}
}
window.customElements.define('driving-safety-filter', DrivingSafetyFilter);